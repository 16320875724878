import React from "react"
import { graphql } from "gatsby"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import BackButton from "../components/back-button"
import { Helmet } from "react-helmet"

export const query =  graphql`
  query TEMPLATE_WP_EVENT_QUERY(
    $id: String!
  ) {
    wpEvent(id: { eq: $id }) {
      ...eventData
      ...eventVariables
      ...eventFeaturedImage
    }
  }
`

const wpEventTemplate = ({ data }) => {
  const { wpEvent: page = {} } = data
  const { 
    pageVariables = {},
    pageMeta = {},
    smartcrawlSeo = {} 
  } = page
 
  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "Event",
          "name": page.title,
          "description": page.excerpt.replace(/(<([^>]+)>)/gi, ""),
          "image": page.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData.images?.fallback?.src,
          "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "startDate": page.eventMeta?.startDate,
          "endDate": page.eventMeta?.endDate,
          "location": [
            {
              "@type": "Place",
              "name": "Blackgang Chine",
              "address": {
                "streetAddress": "Blackgang Chine",
                "addressLocality": "Nr Ventnor",
                "addressRegion": "Isle of Wight",
                "postalCode": "PO38 2HN",
                "addressCountry": "United Kingdom"
              }
            }
          ],
          "organizer": {
            "@type": "Organization",
            "name": "Blackgang Chine",
            "url": "https://blackgangchine.com"
          }
        })}
        </script>
      </Helmet>
      <Seo post={data.wpEvent} />
      {pageVariables.primaryColour && 
        <style dangerouslySetInnerHTML= {{__html: `
        
        `}} />
      }

      <h1 className="visually-hidden">{page.title}</h1>
      <main dangerouslySetInnerHTML={{ __html: page.content }} />
      <div className="py-5">
        <BackButton/>
      </div>
    </React.Fragment>
  )
}

export default wpEventTemplate